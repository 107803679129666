import {defineStore} from 'pinia'
import axios from 'axios'
import jwtdecode from "jwt-decode";
import cfg from './cfg.json'

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

export const useAuthStore = defineStore("authStore", {
    state: () => ({
        input: {
            email: "",
            password: ""
        },
        token: "",
        user: "",
        codeTimer:null,
        codeReqTick:0,
        timerTick:0,
        countdown:0,
        loginPhase:0 
        
    }),
    getters:{
        payload() {
            const dec1 = JSON.parse(atob(this.token.split('.')[1]))
            //const decod = parseJwt(response.data.token)


            return dec1
        },
        isLoggedIn() {
            return !!this.token;
          },

         
 
    },
    actions:{

        startTimer() {
            this.timer = setInterval(() => {
                this.timerTick = Math.round((new Date().getTime() / 1000))

                this.countdown = this.codeReqTick - this.timerTick

                if (this.countdown <= 0) {
                    clearTimeout(this.timer)
                    this.loginPhase = 0

                }
              }, 1000)
        },
        async getAuthCode(){
           
            const params = {
                email: this.input.email,
            
            }

            axios.post('http://'+ cfg.srvHost +':'+ cfg.srvPort +'/auth/sendAuthCode', params, {
                headers: {
                    "content-type": "application/json",
                },
            })
              .then(response => {
                
                //const payload = JSON.parse(atob(response.data.token.split('.')[1]))
                //console.log(response);
                this.codeReqTick = Math.round((new Date().getTime() / 1000)) + 60  
                this.startTimer()
  
                this.loginPhase = 1
                
              })
              .catch(function (error) {
                console.log(error);
              })


        },

        async login () {
            const params = {
                email: this.input.email,
                authCode: this.input.authCode,
            
            }

            axios.post('http://'+ cfg.srvHost +':'+ cfg.srvPort +'/auth/login', params, {
                headers: {
                    "content-type": "application/json",
                },
            })
              .then(response => {

                const payload = JSON.parse(atob(response.data.token.split('.')[1]))
                //var decoded = jwtdecode(response.data.token)
                const decod = parseJwt(response.data.token)
                this.token = response.data.token

                console.log(response)

                 
              })
              .catch(function (error) {
                console.log(error);
              })
        },
        async logout(){
            localStorage.clear()
            location.reload()
        },
 
    },
     persist: true,
     persist: {
        storage: localStorage, // data in sessionStorage is cleared when the page session ends.
      },
    // persist: {
    //     storage: persistedState.sessionStorage,
    //   },
})